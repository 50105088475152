<template>
  <div>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-cohorts' }">
              Cohorts
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Manage Cohorts
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <feather-icon
            icon="ClipboardIcon"
            size="24"
          />
          Manage Cohorts
        </h2>
      </b-col>
      <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >
        <!-- <b-button
          variant="primary"
          :to="{ name: 'create-cohort' }"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-text-top"
          />  Add New
        </b-button> -->
      </b-col>
    </b-row>

    <!-- error message -->
    <section v-if="errorMessage">
      <b-card>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            {{ errorMessage }}
          </div>
        </b-alert>
      </b-card>
    </section>

    <!-- cohorts table -->
    <section v-if="visibleCohorts">
      <!-- table row  -->
      <b-card>
        <b-row v-if="visibleCohorts.length <= 0">
          <b-col>
            No items to show.
          </b-col>
        </b-row>
        <b-row v-if="visibleCohorts.length > 0">
          <!-- <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="perPageSelect"
              >Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
                @change="handlePerPage"
              />
            </b-form-group>
          </b-col> -->

          <!-- <b-col
            md="4"
            sm="8"
            offset="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="sortBySelect"
              >Sort</label>
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      Select
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col> -->

          <!-- <b-col
            md="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="filterInput"
              >Filter</label>
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col> -->

          <!--
              :per-page="perPage"
              :current-page="currentPage"
          -->
          <b-col cols="12 mt-2">
            <b-table
              hover
              responsive
              :items="visibleCohorts"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(cohort_name)="data">
                <b-link
                  @click="handleItemView(data.item)"
                >
                  {{ data.item.cohort_name || '(no name)' }}
                </b-link>
              </template>

              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="closedStatusColor[data.item.won] || 'light-secondary'"
                >
                  <span v-if="data.item.won">YES</span>
                  <span v-else>No</span>
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>

                    <!-- <b-dropdown-item
                      @click="handleItemView(data.item)"
                    >
                      <b-link :to="{ name: 'view-cohort', params: { id: data.item.id } }">
                        <feather-icon
                          icon="EyeIcon"
                          class="mr-50"
                        />
                        <span>View Cohort</span>
                      </b-link>
                    </b-dropdown-item> -->

                    <b-dropdown-item
                      @click="handleItemView(data.item)"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="mr-50"
                      />
                      <span>View Cohort</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="handleItemEdit(data.item)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span>Edit Cohort</span>
                    </b-dropdown-item>

                    <!-- <b-dropdown-item
                      @click="handleItemDelete(data.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </span>
              </template>

            </b-table>
          </b-col>

          <b-col
            cols="12"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTableRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
              @page-click="handlePaginationClick"
            />
          </b-col>
        </b-row>
      </b-card>
    </section>

    <!-- view modal -->
    <b-modal
      id="modal-view-cohort"
      centered
      title="View Cohort"
      size="lg"
      hide-footer
      @hide="resetCohortQuestionUserHistory"
    >
      <b-card-text>
        <validation-observer
          ref="viewCohortForm"
        >
          <b-form
            class="form-container"
            @submit.prevent="handleModalSubmit"
          >
            <b-row>
              <!-- cohort name -->
              <b-col md="6">
                <b-form-group
                  label="Cohort Name"
                  label-for="cohortName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cohort Name"
                    vid="cohortName"
                  >
                    <b-form-input
                      id="cohortName"
                      v-model="cohortForm.cohort_name"
                      type="text"
                      class="form-control"
                      placeholder="Cohort Name"
                      plaintext
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- date started -->
              <b-col md="6">
                <b-form-group
                  label="Date Started"
                  label-for="dateStarted"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Date Started"
                    vid="dateStarted"
                  >
                    <b-form-input
                      id="dateStarted"
                      v-model="cohortForm.date_started"
                      type="text"
                      class="form-control"
                      placeholder="Date Started"
                      plaintext
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- id -->
              <b-col md="3">
                <b-form-group
                  label="ID"
                  label-for="cohortId"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="ID"
                    vid="cohortId"
                  >
                    <b-form-input
                      id="cohortId"
                      v-model="cohortForm.id"
                      type="text"
                      class="form-control"
                      placeholder="ID"
                      plaintext
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- winner -->
              <b-col md="3">
                <b-form-group
                  label="Winner"
                  label-for="winner"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Winner"
                    vid="winner"
                  >
                    <b-form-select
                      id="winner"
                      v-model="cohortForm.won"
                      type="text"
                      class="form-control"
                      placeholder="Winner"
                      readonly
                      disabled
                      plain
                      :options="winnerStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- starting count -->
              <b-col md="3">
                <b-form-group
                  label="Entries"
                  label-for="entries"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Entries"
                    vid="entries"
                  >
                    <b-form-input
                      id="entries"
                      v-model="cohortForm.starting_count"
                      type="text"
                      class="form-control"
                      placeholder="Entries"
                      plaintext
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- final count -->
              <b-col md="3">
                <b-form-group
                  label="Survivors"
                  label-for="survivors"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Survivors"
                    vid="survivors"
                  >
                    <b-form-input
                      id="survivors"
                      v-model="cohortForm.final_count"
                      type="text"
                      class="form-control"
                      placeholder="Survivors"
                      plaintext
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              v-if="cohortTimeline"
              class="mt-2"
            >
              <b-col>
                <h4>Pool History</h4>
                <b-table
                  hover
                  responsive
                  :items="cohortTimeline.history"
                  @row-clicked="handlePoolHistoryClick"
                />
              </b-col>
            </b-row>

            <b-row
              v-if="cohortTimeline && !showIndividualQuestionHistory"
              class="mt-2"
            >
              <b-col>
                <h4>Recent User History</h4>
                <b-table
                  hover
                  responsive
                  :items="cohortTimeline.userHistory"
                />
              </b-col>
            </b-row>

            <b-row
              v-if="showIndividualQuestionHistory"
              class="mt-2"
            >
              <b-col>
                <h4>Question {{ cohortQuestionUserHistory.question_id }} History</h4>
                <b-table
                  hover
                  responsive
                  :items="cohortQuestionUserHistory"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col
                class="mt-2 text-right"
              >
                <!-- cancel button -->
                <b-button
                  variant="outline-secondary"
                  type="button"
                  class="ml-1"
                  @click="handleModalCancel"
                >
                  Close
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- edit modal -->
    <b-modal
      id="modal-edit-cohort"
      centered
      title="Edit Cohort"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <validation-observer
          ref="editCohortForm"
          #default="{invalid, dirty}"
        >
          <b-form
            class="form-container"
            @submit.prevent="handleModalSubmit"
          >
            <b-row>
              <b-col>
                <!-- cohort text -->
                <b-form-group
                  label="Cohort Text"
                  label-for="cohortText"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cohort Text"
                    rules="required"
                    vid="cohortText"
                  >
                    <b-form-input
                      id="cohortText"
                      v-model="cohortForm.text"
                      type="text"
                      class="form-control"
                      placeholder="Cohort Text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="4"
                md="6"
              >
                <!-- open date -->
                <b-form-group
                  label="Open Date"
                  label-for="openDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Open Date"
                    rules="required"
                    vid="openDate"
                  >
                    <flat-pickr
                      id="openDate"
                      v-model="cohortForm.open_date"
                      name="openDate"
                      class="form-control"
                      :config="{ minuteIncrement: 1, enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
              >
                <!-- cutoff date -->
                <b-form-group
                  label="Cutoff Date"
                  label-for="cutoffDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cutoff Date"
                    rules="required"
                    vid="cutoffDate"
                  >
                    <flat-pickr
                      id="cutoffDate"
                      v-model="cohortForm.cutoff_date"
                      name="cutoffDate"
                      class="form-control"
                      :config="{ minuteIncrement: 1, enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
              >
                <!-- close date -->
                <b-form-group
                  label="Close Date"
                  label-for="closeDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Close Date"
                    rules="required"
                    vid="closeDate"
                  >
                    <flat-pickr
                      id="closeDate"
                      v-model="cohortForm.close_date"
                      name="closeDate"
                      class="form-control"
                      :config="{ minuteIncrement: 1, enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                class="mt-2 text-right"
              >
                <!-- cancel button -->
                <b-button
                  variant="outline-secondary"
                  type="button"
                  class="mr-1"
                  @click="handleModalCancel"
                >
                  Cancel
                </b-button>

                <!-- action button -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="invalid || !dirty"
                >
                  Update Cohort
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  BAlert,
  BBadge, BButton,
  BCard, BCardText,
  BDropdown, BDropdownItem,
  BForm, BFormInput, BFormGroup, BFormSelect,
  // BInputGroup, BInputGroupAppend,
  BLink,
  BPagination, BTable,
  BModal,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BLink,
    BPagination,
    BTable,
    BModal,

    flatPickr,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user

      perPage: 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      // totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'cohort_name', label: 'Name', sortable: true },
        { key: 'date_started', label: 'Date Started', sortable: true },
        { key: 'starting_count', label: 'Entries', sortable: true },
        { key: 'final_count', label: 'Survivors', sortable: true },
        { key: 'status', label: 'Winner?', sortable: true },
        // { key: 'actions', label: 'Actions' },
      ],
      statusColor: {
        approved: 'light-success',
        flagged: 'light-warning',
        new: 'light-info',
        rejected: 'light-danger',
      },
      closedStatusColor: {
        false: 'light-success',
        true: 'light-danger',
        1: 'light-success',
        0: 'light-info',
      },
      winnerStatus: [
        { value: null, text: '' },
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' },
      ],
      errorMessage: null,

      // view cohort modal
      cohortForm: {},
      cohortTimeline: {},
      cohortQuestionUserHistory: {},
      // by default the cohort timeline displays user status for the current (most-recent) question
      // by clicking on a question in the pool, one can see the full user history for that individual question
      showIndividualQuestionHistory: false,
      clickedTimelineRow: {},

      // validation
      required,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleCohorts() {
      const digestData = this.$store.getters['cohorts/getAllCohorts']
      return digestData ? digestData.data : []
    },
    totalTableRows() {
      const digestData = this.$store.getters['cohorts/getAllCohorts']
      const linksData = digestData ? digestData.links : 0
      return linksData ? linksData.current_size * linksData.last_page : 0
    },
  },
  created() {
    this.getCohorts()
  },
  methods: {
    getCohorts(args) {
      console.log(args)
      // set table page and size
      const cohortArgs = {
        page: this.currentPage,
        size: this.perPage,
        ...args,
      }

      // get cohorts digest
      this.$store.dispatch('cohorts/fetchAllCohorts', cohortArgs)
    },
    getCohortTimeline(cohortId) {
      if (cohortId) {
        useJwt.getCohortTimeline(cohortId)
          .then(result => {
            const timelineData = result.data.data
            this.cohortTimeline = timelineData
          })
          .catch(err => {
            console.log('getCohortTimeline ERROR', err.response.status, err.response.statusText, err.response.data)

            const errorResponse = err.response.data.data
            const errorMessage = errorResponse.error ? errorResponse.error : errorResponse

            // Error notification on failure
            this.$swal({
              title: 'Error',
              text: `${errorMessage}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      }
    },

    /* COHORT MANAGEMENT */

    /* handleItemView(cohortId) {
      // navigate to the view page for the given resource id
      this.$router.push({ name: 'view-cohort', params: { id: cohortId } })
    }, */
    handleItemView(cohortData) {
      this.cohortForm = {
        ...cohortData,
      }
      this.$bvModal.show('modal-view-cohort')

      this.getCohortTimeline(this.cohortForm.id)
    },
    handleModalCancel() {
      this.$bvModal.hide('modal-view-cohort')
      this.cohortForm = {}
      this.cohortTimeline = {}
    },
    handleItemEdit(cohortData) {
      this.cohortForm = {
        ...cohortData,
        open_date: this.convertToLocalTime(cohortData.open_date),
        cutoff_date: this.convertToLocalTime(cohortData.cutoff_date),
        close_date: this.convertToLocalTime(cohortData.close_date),
      }
      this.$bvModal.show('modal-edit-cohort')
    },
    handleModalSubmit() {
      const cohortFormData = {
        ...this.cohortForm,
        open_date: this.convertToUTC(this.cohortForm.open_date),
        cutoff_date: this.convertToUTC(this.cohortForm.cutoff_date),
        close_date: this.convertToUTC(this.cohortForm.close_date),
      }
      console.log('cohortFormData', cohortFormData)

      useJwt.updateCohort(cohortFormData.id, cohortFormData)
        .then(res => {
          const responseCode = res.status
          const responseText = res.statusText

          console.log('updateCohort', res)
          console.log('Server reponse: ', responseCode, responseText)
          // this.message = `${responseCode} ${responseText}`
          this.message = `${responseText}`

          // show notification
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Cohort Updated',
              icon: 'CohortPlusIcon',
              variant: 'success',
              text: `You have updated a cohort: "${this.cohortForm.text}"`,
            },
          })
          this.$bvModal.hide('modal-edit-cohort')
          this.$router.go(0) // reload page
        })
        .catch(err => {
          console.log('AddCohort ERROR', err.response.status, err.response.statusText, err.response.data)

          const errorResponse = err.response.data.data
          const errorMessage = errorResponse.error ? errorResponse.error : errorResponse

          // Error notification on failure
          this.$swal({
            title: 'Error',
            text: `${errorMessage}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    handlePoolHistoryClick(event, index, element) {
      // console.log('handlePoolHistoryClick', event, index, element)
      const questionId = event.question_id
      useJwt.getCohortQuestionUserHistory(this.cohortForm.id, questionId)
        .then(result => {
          const responseData = result.data.data
          // store the response data
          this.cohortQuestionUserHistory = responseData
          this.cohortQuestionUserHistory.question_id = questionId
          // set display flags
          this.showIndividualQuestionHistory = true
          // now set the style of the row that was just clicked
          element.target.parentElement.className = 'bg-light'
          // check if previously-clicked row style needs refreshing
          if (this.clickedTimelineRow) {
            // reset the visual style of the previously-clicked row
            this.clickedTimelineRow.className = ''
            // if clicked row same as previously clicked row, toggle the modal back to default mode
            if (this.clickedTimelineRow === element.target.parentElement) {
              this.showIndividualQuestionHistory = false
            }
          }
          this.clickedTimelineRow = element.target.parentElement
        })
        .catch(err => {
          console.log('getCohortQuestionUserHistory ERROR', err.response.status, err.response.statusText, err.response.data)

          const errorResponse = err.response.data.data
          const errorMessage = errorResponse.error ? errorResponse.error : errorResponse

          // Error notification on failure
          this.$swal({
            title: 'Error',
            text: `${errorMessage}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    resetCohortQuestionUserHistory() {
      console.log('resetCohortQuestionUserHistory')
      this.showIndividualQuestionHistory = false
      this.cohortQuestionUserHistory = {}
    },

    /* TABLE FILTER AND PAGINATION */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.perPage = filteredItems.length
      this.currentPage = 1

      this.getCohorts()
    },
    handlePaginationClick(bvEvent, currentPage) {
      const conArgs = {
        page: currentPage,
      }
      this.getCohorts(conArgs)
    },
    handlePerPage(sizeValue) {
      const conArgs = {
        size: sizeValue,
      }
      this.getCohorts(conArgs)
    },

    /* MISCELLANEOUS */

    convertToLocalTime(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD h:mm A')
      return convertedDate
    },
    convertToUTC(dateStr) {
      // convert date to UTC
      const localDate = moment(dateStr).local()
      const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
      return convertedDate
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-control-plaintext {
  padding: 0.438rem;
}

</style>
